<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="database" title="Formulario de Respostulación" collection="repostulacion" templateTable="TableRepostulacion" templateTableModal="TableRepostulacionModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const database = computed(() => store.getters.getDBDashboard)
    // eslint-disable-next-line no-unused-vars
    const mongo = computed(() => store.getters.getMongoFeriasURL)
    // const mongo = 'https://api.idastage.com/api/'

    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    
    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        nombre: 'Nombre',
        apellido_paterno: 'Apellido paterno',
        apellido_materno: 'Apellido materno',
        nacionalidad_nombre: 'Nacionalidad',
        sede_nombre: 'Sede',
        carrera_nombre: 'Carrera',
        tipo_documento: 'Tipo de  documento',
        numero_documento: 'Nº de documento',
        email: 'E-mail',
        codigo_celular: 'Código celular',
        celular: 'Número celular',
        codigo_area: 'Código teléfono',
        telefono: 'Número teléfono',
        utm_source: 'UTM Source',
        utm_medium: 'UTM Medium',
        utm_campaign: 'UTM Campaign',
        device: 'Dispositivo'
      },
      query: {
        collection: 'repostulacion',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
